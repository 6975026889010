.statement {
    transition: all 200ms ease-in-out;
}

.statement:hover {
    background-color: #dbe7f0;
}

/* .col-container {
    display: table;
    width: 100%;
}

.col {
    display: table-cell;    
}

@media only screen and (max-width: 600px) {
    .col { 
        display: block;        
    }
} */