@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
// @import "tailwindcss/base";
// @import "tailwindcss/components";
// @import "tailwindcss/utilities";
.sosmed::hover {
  background-color: yellow;
}

$poppins: "Poppins", sans-serif;

// Here you can add other styles
html {
  box-sizing: 0;
}

body {
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  .input-label {
    font-size: 12px;
    color: #2b3c5e;
    opacity: 0.7;
  }

  ul {
    margin-bottom: 0;
  }
}

h4,
h1,
h2,
h3,
h5,
h6,
u,
b,
p {
  font-family: $poppins;
}

.btn-shadow:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.navtext {
  font-family: $poppins;
  font-size: 13px;
}

.app-header .navbar-toggler-icon {
  display: none;
}

.app-header .navbar-toggler:hover .navbar-toggler-icon {
  display: none;
}

.bground {
  background-color: #ffffff;
  padding: "39px";
}

.tags-input {
  display: flex;
  padding-bottom: 20px;
  align-items: flex-start;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 3px;
}

.inputTag {
  border: none;
  background-color: white;
  margin-top: 20px;
  width: 330px;
}

.inputTag:focus {
  outline: none;
}

.empty {
  margin-top: 15px;
  width: 100%;
}

.alert-tag {
  padding: 0px 5px 0px 5px;
  border-radius: 3px;
  float: left;
}

.alert-tag-name {
  margin-right: 1px;
  background: #335877;
  color: white;
  padding: 5px 5px;
  border-radius: 4px 0 0 4px;
  float: left;
}

.alert-tag-icon {
  padding: 5px 5px;
  border-radius: 0 6px 6px 0;
  float: left;
  color: white;
}

.back-asesmen {
  border-radius: 45px 45px 45px 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 30px 30px 30px 30px;
}

.btn-tipe-asesmen {
  background-color: #2b3c5e;
  border-radius: 5px 5px 5px 5px;
  font-size: 13px;
  color: white;
}

.card-group {
  .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-login-form {
    border: 0;
    padding: 0px;
    border-radius: 20px;

    form {
      .logo-widya-skilloka {
        width: 200px;
        margin-bottom: 40px;
      }

      .forgot-password {
        display: flex;
        justify-content: flex-end;

        .link,
        link:hover {
          color: #2b3c5e;
          text-decoration: none;
        }
      }

      input {
        border: 1px solid #b2bbce;
      }
    }
  }

  .card-login-info {
    border: 0;
    border-radius: 20px;
    flex-grow: 1.5;
    background-color: #335877;
    background-size: cover;
    background-repeat: no-repeat;

    .login-info {
      color: #fff;

      .login-img {
        width: 350px;
        margin-bottom: 60px;
      }

      h2 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}

.login-submit {
  width: 100%;
  padding: 10px 0;
  color: #fff;
  background-color: #335877;
  margin-top: 40px;
}

.app-header .navbar-nav {
  margin-top: -20px;
}

.app-header .navbar-toggler {
  margin-top: -20px;
}

.main {
  background-color: #fff;

  .container-fluid {
    padding: 30px 30px 0;
  }
}

.background-login {
  background-color: #eaeef6;
}

.content-title {
  font-weight: 600;
  color: #2b3c5e;
}

.content-sub-title {
  font-weight: 400;
}

.content {
  padding: 1rem;
}

.content-body {
  margin-bottom: 30px;
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -250px;
}

.app-header {
  border: 0;
  box-shadow: 1px 3px 20px 8px rgba(115, 124, 158, 0.1);

  .navbar-brand {
    width: 250px;
  }

  .navbar-nav {
    margin-right: 30px;
  }

  .nav-item .nav-link .img-avatar {
    width: 35px;
  }
}

.round-100 {
  width: 35px;
  border-radius: 100%;
  height: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0.2px solid #333;
}

.app-body .sidebar {
  flex: 0 0 250px;
}

.sidebar-fixed .sidebar {
  width: 250px;
  background-color: #305574;

  .sidebar-nav {
    margin-top: 30px;
    width: 250px;

    .nav {
      width: 220px;
    }
  }

  .nav-link {
    color: #fff;
    padding: 0.8rem 2.3rem;
    margin-bottom: 10px;

    .nav-icon {
      color: #fff;
    }
  }

  .nav-link:hover,
  .nav-link.active {
    color: #1b568d;
    font-weight: 600;
    background: #edf0f2;
    border-radius: 0 8px 8px 0;

    .nav-icon {
      color: #2b3c5e;
    }
  }

  .nav-dropdown-toggle::before {
    width: 10px;
    height: 10px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }

  .nav-dropdown-toggle:hover::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%232B3C5E' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }

  .nav-dropdown.open {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0 8px 8px 0;
  }

  .yaAllah.active,
  .yaAllah:hover {
    background-color: red;
  }

  // .icon-home:before {
  //     content: "\e901";
  // }
  // .icon-users:before {
  //     content: "\e903";
  // }
  // .icon-fingerprint:before {
  //     content: "\e904";
  // }
  // .icon-calendar:before {
  //     content: "\e905";
  // }
  // .icon-business-outline:before {
  //     content: "\e906";
  // }
}

.barOrange {
  background-color: #f57844;
}

.barYellow {
  background-color: #f6b532;
}

.show-pass {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.shadow-red {
  box-shadow: 0 0.5rem 1rem rgba(244, 67, 54, 0.55) !important;
}

.show-pass:hover {
  color: #23282c;
  cursor: pointer;
}

.menu {
  a {
    text-decoration: none;
    color: #23282c;
  }

  .menu-item {
    // width: 200px;
    // height: 240px;
    // border: 1px solid #dbdce5;
    box-shadow: 3px 3px 10px 1px rgba(14, 14, 14, 0.1);
    border: none;
    border-radius: 15px;
    // padding: 30px 45px 30px 20px;
    box-sizing: border-box;
  }

  .menu-item-profile {
    background: #fff;
  }

  .menu-item-mbti {
    background: #fff;

    .menu-img {
      img {
        width: 86%;
      }
    }
  }

  .menu-img {
    img {
      width: 80%;
    }
  }

  .menu-title {
    font-weight: 600;
  }

  .menu-item:hover {
    transform: scale(1.03);
    transition: all 0.3s ease-in;
    // cursor: pointer;
    // border: none;
    box-shadow: 0px 4px 23px #3e58781a;
  }
}

.nav-tabs {
  border-bottom: 1px solid #c6c6d4;
  margin-bottom: 1px;

  .nav-link {
    text-decoration: none;
    color: #b2bbce;
    border: none;
    border-bottom: 3px solid transparent;
  }

  li {
    margin: 0 5px;
  }

  .nav-link.active {
    color: #1b568d;
    border: none;
    font-weight: 600;
    border-bottom: 3px solid #305574;

    &:focus {
      outline: none;
      background: inherit;
      border-color: inherit;
      border-bottom-color: inherit;
    }
  }

  .nav-link:hover {
    color: #305574;
    font-weight: 600;
    border-bottom: 3px solid #305574;
  }

  li:first-child {
    margin-left: 0;
  }

  li:last-child {
    margin-right: 0;
  }
}

.tab-content {
  border: none;
}

a:not([href]):not([tabindex]):hover {
  color: #305574;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: #b2bbce;
}

// .md-company-header {
//     margin-bottom: 10px;

//     .search-form {
//         color: #B2BBCE;
//         border: 1px solid #A7B7D8;
//         border-radius: 4px;
//     }
// }

.table {
  thead {
    background-color: #f5f6fa;
    border-radius: 5px;

    th {
      border: 0;
      vertical-align: middle;
    }

    .w-5 {
      width: 5%;
    }

    .w-10 {
      width: 10%;
    }

    .w-15 {
      width: 15%;
    }

    .w-20 {
      width: 20%;
    }

    .w-30 {
      width: 30%;
    }

    .w-35 {
      width: 35%;
    }

    .w-50 {
      width: 50%;
    }

    .w-70 {
      width: 70%;
    }
  }

  th,
  td {
    border: 0;
    vertical-align: middle;
  }

  td {
    border-bottom: 1px solid #f5f6fa;
  }
}

.input-group-transparent {
  background-color: transparent;
}

.input-search {
  border-left: none;
}

button:disabled {
  cursor: no-drop;
}

.btn-thin {
  padding: 0.25em 0.4em;
}

.btn-netis-color,
.btn-netis-color:hover {
  color: #fff;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #335877;
  border: none !important;
  width: auto;
}

.btn-netis-danger,
.btn-netis-danger:hover {
  color: #fff;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #f86c6b;
  border: none;
  width: auto;
}

.btn-white,
.btn-white:hover {
  color: #335877;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #fff;
  border: none;
}

.btn-red-custom,
.btn-red-custom:hover {
  color: #f63c3a;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #fff;
  border: none;
}

.btn-circle {
  padding: 15px;
  border: 1px solid #b2bbce;
  border-radius: 50%;
}

.btn-circle:hover {
  cursor: pointer;
  border: 1px solid #b2bbce;
  box-shadow: 0px 4px 23px #3e58781a;
}

.btn-lamaran,
.btn-lamaran:hover {
  padding: 0.4rem 0.4rem;
  border-radius: 8px;
  color: #fff;
  font-size: 0.9em;
  font-weight: 600;
  border: none;
}

.form-control:disabled {
  background-color: #fafbfd;
  cursor: no-drop;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.bank-card {
  width: 380px;
  height: 220px;
  border-radius: 15px;
  border: none;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  line-height: 210px;
  text-align: left;
  padding: 35px;
}

.title-bank {
  color: #e2ecff;
  font-family: $poppins;
  letter-spacing: 0.01em;
  font-weight: bold;
  line-height: 1.5;
  vertical-align: bottom;
  display: inline-block;
}

.input-group {
  width: auto;
}

.modal-dialog {
  // top: 30%;

  .modal-content {
    border: none;
    border-radius: 13px;

    .modal-body {
      padding: 2rem;
    }
  }
}

.vid {
  width: 100%;
  height: 100%;
  border: 0;
  // position: relative;
}

.title {
  font-size: 18pt;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  left: 5px;
  top: 5px;
  position: absolute;
}

.question {
  font-size: 7pt;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  left: 3px;
  top: 20px;
  position: absolute;
  z-index: 999;
}

.smallVid {
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  background-position: right;
  z-index: 99;
  width: 100%;
  height: 100%;
}

.vidwrapperWidya {
  width: 32%;
  height: 32%;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 9;
}

.vidwrapper {
  display: table;
  width: auto;
  position: relative;
}

.play {
  opacity: 0.75;
  background-repeat: no-repeat;
  width: 15%;
  height: 15%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
}

.play2 {
  opacity: 0.75;
  background-repeat: no-repeat;
  width: 30%;
  height: 30%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
  z-index: 999;
}

.pause {
  opacity: 0.05;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
  z-index: 999;
}

.short-guide-container {
  position: fixed;
  bottom: 4rem;
  right: 0.6rem;
  text-align: center;

  img {
    width: 75px;
    cursor: pointer;
  }
}

.fab-whatsapp {
  a {
    text-decoration: none !important;
  }

  position: fixed;
  bottom: 1rem;
  right: 1rem;
  text-align: center;

  img {
    width: 50px;
    cursor: pointer;
  }

  .fab-whatsapp-caption {
    display: none;
  }

  .box-wa {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    transition: opacity 0.3s ease 0s, margin 0.3s ease 0s,
      visibility 0.3s ease 0s;
    background-color: rgb(255, 255, 255);
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    touch-action: auto;
    margin-bottom: 96px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: auto;
    margin-right: 20px;
    width: 300px;

    .closed-btn {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 20px;
      height: 20px;
      opacity: 0.4;
      cursor: pointer;
      transition: all 0.3s ease 0s;
      outline: transparent;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
    }

    .closed-btn::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 2px;
      background-color: rgb(255, 255, 255);
      display: block;
      border-radius: 2px;
      transform: rotate(-45deg);
    }

    .closed-btn::before {
      content: "";
      transform: rotate(45deg);
      position: absolute;
      width: 12px;
      height: 2px;
      background-color: rgb(255, 255, 255);
      display: block;
      border-radius: 2px;
    }

    .logo-wa {
      width: 52px;
      height: 52px;
      display: block;
      position: relative;
      flex-shrink: 0;
    }

    .logo-wa::before {
      content: "";
      bottom: 0px;
      right: 0px;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      background-color: rgb(74, 213, 4);
      display: block;
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      border: 2px solid rgb(9, 94, 84);
    }

    .logo-wa-inner {
      height: 100%;
      width: 100%;
      overflow: hidden;
      border-radius: 50%;
      position: relative;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
    }

    .img-logo-wa {
      min-width: 100%;
      height: 100%;
      flex-shrink: 0;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/logo.jpg");
    }

    .img-logo-wa::after {
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      position: absolute;
      content: "";
      overflow: hidden;
    }

    .header-box-wa {
      background: rgb(9, 94, 84);
      color: rgb(17, 17, 17);
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      padding: 14px 20px;
    }

    .content-box-wa {
      padding: 20px 20px 20px 10px;
      background-color: rgb(230, 221, 212);
      position: relative;
      overflow: auto;
      max-height: 382px;

      .content-flex-wa {
        display: flex;
        z-index: 1;

        .wa-dots {
          background-color: rgb(255, 255, 255);
          width: 52.5px;
          height: 32px;
          border-radius: 16px;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          margin-left: 10px;
          opacity: 0;
          transition: all 0.1s ease 0s;
          z-index: 1;
          box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;

          .wa-dots-inner {
            position: relative;
            display: flex;

            .wa-dots-one {
              height: 5px;
              width: 5px;
              margin: 0px 2px;
              border-radius: 50%;
              display: inline-block;
              position: relative;
              animation-duration: 1.2s;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              top: 0px;
              background-color: rgb(158, 157, 162);
              animation-name: dJHihm;
            }

            .wa-dots-two {
              height: 5px;
              width: 5px;
              margin: 0px 2px;
              background-color: rgb(182, 181, 186);
              border-radius: 50%;
              display: inline-block;
              position: relative;
              animation-duration: 1.2s;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              top: 0px;
              animation-name: goHzKU;
            }

            .wa-dots-three {
              height: 5px;
              width: 5px;
              margin: 0px 2px;
              background-color: rgb(182, 181, 186);
              border-radius: 50%;
              display: inline-block;
              position: relative;
              animation-duration: 1.2s;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              top: 0px;
              animation-name: krEmjx;
            }
          }
        }

        .content-message-wa {
          padding: 7px 14px 6px;
          background-color: rgb(255, 255, 255);
          border-radius: 0px 8px 8px;
          position: relative;
          transition: all 0.3s ease 0s;
          opacity: 0;
          transform-origin: center top;
          z-index: 2;
          box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
          margin-top: 4px;
          margin-left: -54px;
          max-width: calc(100% - 66px);
          opacity: 1;
          text-align: left;

          .wa-author {
            opacity: 1;
            font-size: 13px;
            font-weight: 700;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.4);
          }

          .wa-text {
            font-size: 14px;
            line-height: 19px;
            margin-top: 4px;
            color: rgb(17, 17, 17);
          }
        }

        .content-message-wa::before {
          position: absolute;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC");
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: contain;
          content: "";
          top: 0px;
          left: -12px;
          width: 12px;
          height: 19px;
        }
      }

      .gotoChat:hover {
        box-shadow: rgba(0, 0, 0, 0.13) 0px 3px 1.5px;
      }

      .gotoChat {
        padding: 8px 12px;
        border-radius: 20px;
        border: none;
        background: rgb(79, 206, 93);
        color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        cursor: pointer;
        position: relative;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        margin: 20px;
        overflow: hidden;
        appearance: none;

        .text-wa-goto {
          margin-left: 8px;
          margin-right: 8px;
          z-index: 1;
          color: rgb(255, 255, 255);
        }
      }
    }

    .content-box-wa::before {
      display: block;
      position: absolute;
      content: "";
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      z-index: 0;
      opacity: 0.08;
      background-image: url("https://elfsight.com/assets/chats/patterns/whatsapp.png");
    }
  }

  // @include media-breakpoint-up(sm) {
  //   transform: translateY(15px);
  //   transition: transform 100ms ease-in-out;
  //   .fab-whatsapp-caption {
  //     display: inline;
  //     opacity: 0;
  //     transition: opacity 250ms ease-in-out;
  //   }
  //   &:hover {
  //     transform: translateY(0);
  //     .fab-whatsapp-caption {
  //       opacity: 1;
  //     }
  //   }
  // }
}

// .modal-dialog.modal-lg {
//     top: 25%;
// }

.banner-upgrade {
  border: none;
  border-radius: 15px;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 13px #3e58781a;
}

.banner-information {
  border-radius: 15px;
  background-color: #fbfaff;
  border: none;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 200px;
  box-shadow: 0px 4px 13px #3e58781a;
}

.title-information-company {
  color: #2b3c5e;
  font-family: $poppins;
  letter-spacing: 0.02em;
  font-weight: strong;
  font-size: 1.2em;
}

.title-information-companyProfile {
  color: #2b3c5e;
  font-family: $poppins;
  text-align: center;
  font-weight: 400;
  line-height: 18.75px;
  font-size: 16px;
}

.subtitle-companyProfile {
  font-family: $poppins;
  text-align: center;
  font-weight: 400;
  line-height: 16.41px;
  font-size: 14px;
}

.activeTitle {
  border: 2px solid green;
}

.jobs-companyProfile {
  color: black;
  font-family: $poppins;
  text-align: center;
  font-weight: 400;
  line-height: 16.41px;
  font-size: 14px;
}

.title-menu-user {
  color: #2b3c5e;
  font-family: $poppins;
  letter-spacing: 0.02em;
}

.title-upgrade {
  color: #e2ecff;
  font-family: $poppins;
  letter-spacing: 0.01em;
  font-weight: bold;
}

.button-upgrade {
  color: #e2ecff;
  font-family: $poppins;
  letter-spacing: 0.01em;
  padding: 5px 18px;
  border: 1.3px solid #e2ecff;
  border-radius: 5px;
  background-color: transparent;
}

.button-upgrade:hover {
  cursor: pointer;
  box-shadow: 0px 0px 4px #fff;
}

.padding-0 {
  padding: 0px;
}

.padding-top-bottom-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.label-information {
  color: #7b849a;
  font-family: $poppins;
}

.input-information[disabled] {
  color: #2b3c5e;
  font-family: $poppins;
  border: none;
  border-bottom: 1px solid #d9dde6;
  background-color: transparent;
  font-weight: 600;
  cursor: default;
}

.footer-list li:hover {
  opacity: 0.8;
}

.footer-list img:hover {
  opacity: 0.8;
}

.personnel-name,
.personnel-name:hover {
  text-decoration: none;
  color: #1e3f80;
}

.semi-bold {
  font-weight: 600;
}

.action-text {
  margin: 0;
  font-weight: 600;
  color: #1e3f80;
}

.action-text:hover {
  cursor: pointer;
}

.img-width {
  height: 100px;
}

.button-add-doc,
.button-add-doc:hover {
  width: 30%;
  padding: 10px 0;
  color: #fff;
  background-color: #335877;
  margin-top: 20px;
}

.frame-profile-picture {
  width: 210px;
  // height: 140px;
  // border: 3px solid #ccc;
  box-shadow: 0px 3px 12px #2b3c5e1a;
  background: #fff;
  // margin: auto;
  padding: 10px 10px;

  img {
    width: 100%;
    // height: 100%;
  }
}

.frame-profile-picture-empty {
  width: 210px;
  height: 140px;
  // border: 3px solid #ccc;
  box-shadow: 0px 3px 12px #2b3c5e1a;
  background: #fff;
  margin: auto;
  padding: 10px 10px;

  img {
    width: 100%;
    height: 100%;
  }
}

.img-avatar {
  width: 35px;
  margin-right: 10px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.loading {
  position: absolute;
  top: 40%;
  left: 50%;
}

.img-document {
  max-width: 350px;
}

.input-personnel-document {
  width: 300px;
  height: 180px;
  border: 2px dashed #b2bbce;
  border-radius: 15px;

  .input-personnel-document-button {
    padding: 15px;
    border: 1px solid #b2bbce;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .input-personnel-document-button:hover {
    cursor: pointer;
  }

  span {
    color: #b2bbce;
    font-size: 2rem;
  }
}

.form-check-input {
  position: relative;
  margin-left: 0;
  top: 2px;
}

.form-check-label {
  color: #5c6873;
}

.no-data-message {
  color: #5c6873;
  font-style: italic;
}

.autocomplete-dropdown-container {
  position: absolute;
  width: 85%;
  z-index: 1;
  border-radius: 0.25rem;
  background-color: #fff;
  top: 47px;
  left: 10px;
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);

  div {
    padding: 0.275rem 0.75rem;
  }

  div:last-child {
    margin-bottom: 0.375rem;
  }

  .suggestion-item--active {
    background-color: #eee;
    cursor: pointer;
  }

  .suggestion-item {
    cursor: pointer;
  }
}

.radius-number {
  margin-top: 28px;
}

input[type="range"] {
  -webkit-appearance: none;
  overflow: hidden;
  border: 0;
}

input[type="range"]:focus {
  outline: none;
  box-shadow: none;
}

input[type="range"]:hover {
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #335877;
  height: 5px;
  border-radius: 10px;
}

input[type="range"]::-moz-range-track {
  background: #335877;
  height: 5px;
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  background: #335877;
  margin-top: -7px;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  height: 18px;
  width: 18px;
  background: #335877;
  margin-top: -7px;
  border-radius: 50%;
}

.react-datepicker-component {
  .react-datepicker-input {
    min-width: 0 !important;

    input {
      font-size: 0.875rem !important;
      font-weight: 400 !important;
      line-height: 1.5;
      color: #5c6873 !important;
      background: #fff !important;
      padding-right: 32px !important;
      background-clip: padding-box;
      border: 1px solid #e4e7ea;
    }
  }
}

.step-assesment {
  min-height: fit-content;
}

.fHIrZs {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-custom-datepicker-component .react-datepicker-input.has-value c {
  padding-right: 32px;
}

.react-datepicker-component.is-disabled {
  background-color: #fafbfd !important;
  cursor: no-drop;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  .react-datepicker-input,
  input {
    background-color: #fafbfd !important;
    cursor: no-drop;
  }
}

.notif-card {
  border: 1px solid #dbdce5;
  box-shadow: 0px 4px 10px #3e58781a;
  border-radius: 15px;
  background: #fff;
  box-sizing: border-box;
  padding: 5px 10px;
  cursor: pointer;
}

.notif-card:hover {
  background-color: #fdfdfe;
}

.dashboard-notif-wrapper {
  height: 784px;
}

.dashboard-notif {
  background-color: #fbfbfb;
  overflow: scroll;
}

.brand-card,
.dashboard-card {
  border: 1px solid #dbdce5;
  // border: none;
  // box-shadow: 0px 4px 23px #3e58781a;
  border-radius: 15px;
  background: #fff;
  box-sizing: border-box;
  color: #2b3c5e;

  .brand-card-header {
    border-radius: 15px 15px 0 0;
  }

  .card-header {
    border-bottom: 0;
    border-radius: 15px 15px 0 0;
  }

  .widget01-children {
    background-color: #fafbfd;
    margin: 0.5rem 0 0.75rem 0;
    padding: 0 0.5rem;
  }

  hr {
    margin: 0.275rem 0;
  }
}

.badge-btn:hover {
  cursor: pointer;
  box-shadow: 0px 4px 23px #3e58781a;
}

.check-in {
  background-color: #ddf1f1;
}

.check-out {
  background-color: #f7ebe3;
}

.top-modal {
  top: 100px;
  flex: 1;
}

.flexbox-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flexboxi-1 {
  flex: 1;
}

.flexboxi-2 {
  flex: 2;
}

.height-100 {
  height: 100%;
}

.margin-30 {
  margin: 0px;
  background-color: #333;
}

.webcam {
  position: relative;
}

.button-capture {
  // position: relative;
  width: 75px;
  height: 75px;
  border: 5px solid #498fca;
  border-radius: 100px;
  background-color: #fff;
  z-index: 999;
  // margin-top:-130px;
  opacity: 0.9;

  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 30px;
}

.bottom-capture {
  max-width: 80%;
  color: #fff;
  z-index: 999;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
}

.fixedDisplay {
  position: fixed;
  height: 100%;
  width: 100%;
}

.splash {
  background-color: rgba(255, 255, 255, 0.8);
  //  opacity: 0.3;
  width: 100%;
  height: 100%;
  margin: 0px;
}

.top-50 {
  margin-top: 50px;
}

textarea {
  resize: none;
}

.dashboard-wrapper {
  border: 1px solid #e6e4e4;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 4px 23px #3e58781a;
}

.dashboard-body-personnel {
  background-size: contain;
  background-repeat: no-repeat;
  height: 121.1px;
  background-position: center;
}

.icon-wrapper {
  padding: 30px 20px;
}

.title-wrapper {
  padding: 10px 5px 5px;
  background-color: #489fc1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.title-dashboard {
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.number-dashboard {
  font-weight: bold;
  color: #fff;
}

.search-location {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 80%;
}

// Landing Page
.landing-page {
  margin-top: 72px;
}

title-wrapper-header-mobile .custom-nav {
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding-bottom: 20px; */
}

.onScroll {
  box-shadow: 1px 3px 20px 8px rgba(115, 124, 158, 0.1);
  // background-color: #5aaef7;
  color: blue;
  background-color: white;
  padding: 1rem 1rem !important;
}

.navbarMenu {
  letter-spacing: 1px;
  font-family: $poppins;
  color: white;
}

.navbarMenu-lk {
  letter-spacing: 1px;
  font-family: $poppins;
  color: blue;
}

.onScroll .navbarMenu {
  color: blue;
}

.navbar-light .navbar-toggler {
  border-color: transparent;
}

.navbar .button:focus {
  outline: none;
}

.link-mbti:hover {
  text-decoration: none;
  opacity: 0.7;
}

/* .navbar-toggler-icon {
    background-image: url('./closenav.svg');
} */

.navbar-logo {
  width: 200px;
}

.navbar-light .navbar-nav .nav-link,
.modal-drawer .nav-link {
  color: #305574;
  font-size: 14px;
  font-weight: 600;
  margin-right: 20px;
}

.modal-drawer {
  max-width: 100%;
  height: 100%;
  margin: 0;

  .drawer-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-content {
    border-radius: 0;
    height: 100%;
    padding: 8px 0;
  }

  ul {
    list-style: none;
    padding: 0 22px;
    color: #305574;
    font-size: 16px;
    font-weight: 500;

    li {
      padding: 12px 0;
      border-bottom: 1px solid rgba(228, 228, 228, 0.3);
    }
  }

  .nav-link {
    font-weight: 500;
    font-size: 16px;
  }

  .btn-landing {
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-radius: 10px;
  }
}

.ReactRibbons_leftLargeRibbonText_1SAojwHIrk8Rz0e2pG0aF_ {
  top: 19px !important;
  font-size: 0.8em !important;
  left: 4px !important;
}

.btn-landing {
  font-weight: 600;
  border-radius: 12px;
  padding: 3px 20px;
  text-align: center;
}

.btn-akun {
  border-radius: 8px;
  font-size: 0.9em;
  padding: 7px 14px;
  font-weight: 600;
  color: #335877;
  border: 1px solid #335877 !important;
  background-color: white;

  &.btn-akun:hover {
    color: #fff;
    padding: 7px 14px;
    font-size: 0.9em;
    font-weight: 600;
    background-color: #335877;
    border: none !important;
    width: auto;
  }
}

.btn-netis {
  background-color: #305574;
  color: #fff;
}

.btn-navbar {
  font-size: 14px;
}

.btn-hero {
  font-size: 18px;
  text-transform: uppercase;
  padding: 9px 20px;
}

.btn-grey {
  font-size: 16px;
  background-color: #eaedf5;
  color: #305574;
  padding: 8px 22px;
}

.btn-grey:hover {
  color: #305574;
}

.btn-landing:hover {
  color: #fff;
  text-decoration: none;
}

.nav-link:hover {
  color: #305574;
}

.hero {
  color: #305574;
  min-height: 100vh;
}

.hero-content {
  max-width: 40%;
  position: absolute;
  top: 170px;

  h1,
  h5 {
    font-family: $poppins;
    font-weight: 600;
  }
}

.hero-content p,
.about p {
  font-size: 14px;
  line-height: 22px;
}

.hr-main {
  width: 160px;
  border-top: 2px solid #ffb95b;
  margin-bottom: 25px;
}

.unduh-app {
  width: 200px;
}

.line {
  width: 50px;
  height: 5px;
  background-color: #fff;
  border-radius: 30px;
}

.box-control {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  z-index: 999;
}

.box-control-palmistry {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  z-index: 999;
}

.hand-background {
  background-image: url("../../assets/img/hand.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 998;
}

.box-control-up {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
}

.gambaryangke2 {
  width: 100%;
  background-size: cover;
}

.header-landing-page {
  background-image: url("../../assets/img/Bg-LP.png");
  background-size: cover;
  width: 100%;
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-position-y: 100%;
}

.landingPage4 {
  min-height: 60vh;
}

.paddingCard {
  padding-left: 10%;
  padding-right: 10%;
}

@include media-breakpoint-down(xs) {
  .header-landing-page {
    background-image: url("../../assets/img/backgroundViewMobile.png");
    background-size: cover;
    width: 100%;
    position: relative;
    height: 100%;
    background-repeat: no-repeat;
    background-position-y: 100%;
  }

  .landingPage4 {
    min-height: 50vh;
    margin-top: 30px;
    margin-bottom: 80px;
  }

  .paddingCard {
    padding-left: 0;
    padding-right: 0;
  }

  .asesmenCard {
    border: 1px solid white;
    border-radius: 20px 20px 20px 20px;
    margin-top: 7px;
  }
}

.header-landing-page-old {
  background-image: url("../../assets/img/waveheadercontent.png");
  background-size: cover;
  width: 100%;
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-position-y: 100%;
}

.detail-landing-page {
  background-image: url("../../assets/img/Bg-LP.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.img-mbti {
  height: 250px;
}

.img-mbti:hover {
  transform: scale(1.1);
  opacity: 0.9;
  transition: all 0.3s ease-in;
}

.player-wrapper {
  position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  background-color: black;
}

.hand-background-kanan {
  background-image: url("../../assets/img/hand.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 998;
}

.hand-background-kiri {
  background-image: url("../../assets/img/hand-left.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 998;
}

.icon-next {
  position: absolute;
  bottom: 20%;
  left: 60%;
  cursor: pointer;
}

.icon-video-single {
  position: absolute;
  bottom: 20%;
  left: 47%;
  cursor: pointer;
}

.icon-video {
  position: absolute;
  bottom: 20%;
  left: 50%;
  cursor: pointer;
}

.icon-question {
  position: absolute;
  bottom: 20%;
  left: 40%;
  cursor: pointer;
}

.icon-replay {
  position: absolute;
  bottom: 20%;
  left: 30%;
  cursor: pointer;
}

.countdown-video {
  position: absolute;
  top: 15%;
  left: 47%;
}

.warning-video {
  position: absolute;
  top: 0%;
  z-index: 999;
}

.icon-spinner {
  position: absolute;
  bottom: 15.4%;
  left: 46.7%;
  width: 80px;
  height: 80px;
}

.asesmenCard {
  border: 1px solid white;
  border-radius: 20px 20px 20px 20px;
  margin-left: 10px;
  margin-right: 7px;
  margin-top: 7px;
}

.asesmenCard:hover {
  box-shadow: -3px 3px 22px rgba(115, 124, 158, 0.1);
}

.footer {
  // background-color: #1B568D;
  box-sizing: border-box;
  border-radius: 60px 60px 0 0;
  padding-top: 50px;
  padding-bottom: 20px;
  background-image: url("../../assets/img/Bg-footer.png");
  background-size: cover;
  background-position-y: 50%;
}

.react-multi-carousel-dot--active button {
  background: #1b568d !important;
  border: none !important;
}

.logo_white {
  width: 300px;
}

.footer-left {
  width: 420px;
}

.footer-right {
  width: 220px;
}

.footer-divider {
  text-align: left;
  width: 45px;
  border-top: 3px solid #ffb95b;
  margin-top: 8px;
  margin-left: 0;
}

footer p {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 24px;
}

.sosmed-lg img {
  width: 30px;
}

.hr-footer {
  border-top: 1px solid #fff;
  opacity: 0.6;
  margin: 22px 0;
  margin-bottom: 18px;
}

.copyright {
  color: #fff;
  font-size: 12px;
  line-height: 22px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.navbar {
  padding: 2rem 1rem;
}

.navbar-expand-lg .navbar-nav .active {
  background-color: #ffdb14;
  border-radius: 0.25rem;

  .navbarMenu {
    color: green;
  }
}

.btn-account {
  border: none;
  background-color: #1b568d;
  color: #1b568d;
  border-radius: 8px;
  padding: 8px 25px;
  font-size: 0.8em;
  font-weight: bold;
}

.btn-account-navbar {
  border: 1px solid #1b568d;
  background-color: #fff;
  color: #1b568d;
  border-radius: 8px;
  padding: 8px 25px;
  font-size: 0.8em;
  font-weight: bold;
}

.btn-account-navbar:hover {
  background-color: #1b568d;
  border: none;
  color: #fff;
}

.link-job {
  color: #fff;
}

.link-job:hover {
  color: #17a2b8;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
  background-color: #ffdb14;
  border-radius: 0.25rem;
  color: white;
}

.close-drawer .navbar-toggler-icon {
  background-image: url("../../assets/img/closenav.svg");
}

.title-header {
  font-family: $poppins;
  font-weight: 900;
  line-height: 1.3em;
  font-size: 2.7em;
}

.title-wrapper-header {
  margin-top: 20px;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 15px, 15px, 25px, 15px;
  background-position-x: 0%, 50%, 90%, 80%;
  background-position-y: 0%, 80%, 90%, 20%;
}

.title-wrapper-header-mobileLp {
  margin-top: -50px;
  margin-bottom: 50px;
  margin-left: 1%;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 15px, 15px, 25px, 15px;
  background-position-x: 0%, 50%, 90%, 80%;
  background-position-y: 0%, 80%, 90%, 20%;
}

.card-quote {
  border: none;
  margin: 25px 0px;
  background-color: #f7f7f7 !important;
}

// .card-border:hover {
//   box-shadow: 0px 12px 30px #8d8d8d29;
//   transition: 0.3s;
// }
.card-border {
  // border: 1px solid #cfcfcf;
  padding: 25px;
  border-radius: 20px;
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.quote-title {
  color: #1b568d;
  font-weight: bold;
}

.quote-by {
  color: #1b568d;
  font-size: 1em;
}

.react-multi-carousel-dot button {
  border: none;
  width: 15px;
  margin-top: 30px;
  height: 15px;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 0 !important;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;

  &::before {
    z-index: 0 !important;
  }
}

.none-list {
  list-style-type: none;
}

.sub-lists li {
  line-height: 2.1em;
}

.footer-list {
  list-style-type: none;

  li {
    color: #fff !important;
    line-height: 1.9em;
    font-size: 14px !important;
  }
}

.wrap-content {
  padding: 180px 30px 50px;
}

.header-logo-mobile {
  display: none;
}

.header-logo-web {
  margin-top: -70px;
  margin-bottom: 60px;
}

.title-landingpage {
  color: #02213f;
  text-align: center;
  font-size: 32px;
  font-family: $poppins;
}

.title-landingpage2 {
  color: #02213f;
  text-align: center;
  font-size: 25px;
  font-family: $poppins;
}

.title-landingpage3 {
  padding-left: 30px;
  padding-right: 15px;
  color: #02213f;
  text-align: center;
  font-size: 25px;
}

.title-landingpage-mobile {
  color: #02213f;
  text-align: center;
  font-size: 26px;
  margin-top: 10%;
  font-family: $poppins;
}

.line-horizontal {
  width: 162px;
  height: 5px;
  background: #fea733;
  border: none;
}

.path1 {
  width: 15px;
  position: absolute;
  float: right;
}

.path2 {
  width: 15px;
  position: absolute;
}

.text-headerlp {
  text-align: center;
  font-size: 16px;
}

.icon-path1 {
  margin-bottom: -65px;
  margin-left: 200px;
}

.icon-path2 {
  margin-top: -100px;
  margin-right: 200px;
}

.Bg-iphone1 {
  width: 100%;
  position: relative;
}

.Bg-iphone2 {
  width: 100%;
  margin-left: 15%;
  position: relative;
  margin-top: 13%;
}

.Bg-iphone3 {
  width: 170%;
  left: -201px;
  position: absolute;
}

.Bg-mobile-ip2 {
  width: 250px;
  margin-left: -30px;
  width: 130%;
  // margin-top: 7%;
}

.Bg-mobile-ip3 {
  left: -15px;
  margin-right: -15px;
  position: relative;
  width: 100%;

  // margin-top: 40px;
  // margin-bottom: 20px;
}

.Bg-LP2 {
  width: 100%;
  margin-bottom: -50px;
}

.text-sublandingpage2 {
  color: white;
  font-size: 14px;
  line-height: 24px;
}

.text-landingpage4 {
  font-size: 15px;
  margin-left: -50px;
  margin-top: 40px;
}

.canvas-Lpmobile {
  // margin-top: -55%;
  // border: 2px solid white;
  // border-radius: 40px 40px 40px 40px;
  // padding: 50px 10px 50px 10px;
  margin-left: 20px;
  margin-right: 20px;
  // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}

.text-headerlp-mobile {
  font-size: 15px;
  margin-left: -50px;
  margin-top: 55px;
}

.background-caraousel {
  background: url("../../assets/img/Bg-Caraousel.png");
  background-size: cover;
  margin-right: 60px;
  height: 150%;
}

.navbar-light .burgerHome .navbar-toggler-icon {
  background-image: url("../../assets/img/burger-white.svg");
}

.onScroll .burgerHome .navbar-toggler-icon {
  background-image: url("../../assets/img/burger.svg");
}

.navbar-light .burgerNonHome .navbar-toggler-icon {
  background-image: url("../../assets/img/burger.svg");
}

.active-paginate {
  background-color: #235b90;
  color: #f0f0f0 !important;
  font-weight: bold;
}

.menu-profile {
  height: 300px;
  padding: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.btn-menu {
  background-color: #18568b;
  border: none;
  padding: 7px 40px;
  border-radius: 8px;
  color: #fff;
  font-size: 0.9em;
  font-family: "Staatliches";
  box-shadow: 0px 5px #b57b2e;
  vertical-align: bottom;
  letter-spacing: 0.1em;
}

.btn-hands {
  position: absolute;
  z-index: 998;
  top: 5%;
  right: 5%;
}

.btn-menu:hover {
  box-shadow: 0px 5px 3px #555;
}

.card-header-menu {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 15px;
  border: none;
  color: #fff;
  text-align: center;
  background-color: #1b568d;
  width: 100%;
}

.content-mobile {
  display: none;
}

.wrap-list-personality {
  background-color: #fafafa !important;
}

.bg-user {
  background-image: url("../../assets/img/avatar.png");
}

.modal-drawer .active {
  font-weight: bold;
  background-color: #f4f4f5 !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.card-collapse {
  box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding-right: 1.5rem;
  margin-bottom: 0.85rem;
  cursor: pointer;
}

.wa-mobile {
  display: none;
}

.button-group-modal {
  justify-content: flex-end !important;
  display: contents;
}

//Job Placement
.list-job-placement {
  padding: 10px;
  margin-top: 56px;

  h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #2b3c5e;
    font-family: $poppins;
  }

  .job-placement {
    border-radius: 10px;
    margin: 10px 0;

    h1 {
      font-size: 18px;
      color: #2b3c5e;
      font-family: $poppins;
      font-weight: bold;
    }

    h2 {
      font-size: 16px;
      color: #2b3c5e;
      font-family: $poppins;
    }

    h3 {
      font-size: 14px;
      color: #2b3c5e;
      font-family: $poppins;
    }

    h4 {
      font-size: 12px;
      color: #2b3c5e;
      font-family: $poppins;
    }

    .job-placement-desc {
      display: flow-root;
      text-align: center;
      // display: flex;
      flex-direction: column;
      align-items: flex-end;

      .button-job {
        color: white;
        border-radius: 10px;
        border: none;
        font-size: 14px;
      }
    }

    .image-job-placement {
      width: 7.5rem;
      height: 7.5rem;
      margin-top: 1rem;
      object-fit: cover;
      background-color: white;
      padding: 0.5rem;
    }
  }
}

.job-detail-page {
  .job-detail-list {
    .image-job-placement {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
      object-fit: cover;
      background-color: white;
      padding: 1.5rem 0.5rem;
    }
  }

  .breadcrumb-header {
    position: fixed;
    width: 100%;
    z-index: 1000;

    .breadcrumb {
      background-color: #e2e2e2;
      border: none;
      padding-left: 6.5rem;

      .breadcrumb-item {
        a {
          color: #2b3c5e;
          font-weight: 500;
        }
      }
    }
  }

  h1 {
    font-size: 18px;
    color: #fff;
    font-family: $poppins;
    font-weight: bold;
  }

  ul {
    margin-bottom: 1rem;
  }

  // li {
  //   font-size: 18px;
  //   color: #2b3c5e;
  //   font-family: $poppins;
  //   list-style: none;
  // }
  .job-placement {
    width: 100%;
    border: none;
    background-color: #18568b;
    border-radius: 10px;
    padding: 32px 43px;

    h2 {
      font-size: 16px;
      color: white;
      font-family: $poppins;
    }

    h3 {
      font-size: 14px;
      color: #fff;
      font-family: $poppins;
    }

    h4 {
      font-size: 12px;
      color: #fff;
      font-family: $poppins;
    }

    p {
      color: #fff;
      margin: 0;
    }
  }

  .job-description {
    .job-description-title {
      background-color: #18568b;
      border-radius: 10px 10px 0 0;
      padding: 16px 43px;

      h1 {
        font-family: $poppins;
        margin-bottom: 0;
        color: white;
      }
    }

    .job-desc-list {
      background-color: #f5f6fa;
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
      padding-top: 1rem;
    }
  }

  .job-description-company {
    .job-description-title-company {
      background-color: #18568b;
      border-radius: 10px 0 20px 0;
      padding: 5px 5px 5px 5px;
      width: 250px;
      font-family: $poppins;
      margin-bottom: 0;
      color: white;
      font-size: 14px;
    }

    .visitMe {
      line-height: 8px;
      font-weight: 500;
    }

    .jobs-logo {
      height: 104px;
      width: 104px;
      border: 1px solid #e0e0e0;
      // margin-top: 10px;
      border-radius: 15px 15px 15px 15px;
    }

    .jobs-logo-company {
      width: 232px;
      height: 233px;
      border-radius: 15px 15px 15px 15px;
      border: 1px solid #e0e0e0;
    }

    .job-desc-list-company {
      background-color: white;
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
      margin-right: 35px;
      border-radius: 10px 10px 10px 10px;
    }
  }

  .card-job-company {
    border: 3px solid white;
    border-radius: 20px 20px 20px 20px;
  }

  #card-company:hover {
    box-shadow: -10px 15px 40px rgba(0, 0, 0, 0.15);
  }

  .img-job-company {
    margin-top: 37px;
    width: 80px;
    height: 25px;
  }

  .sosmedCard {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    border: none;
  }

  .button-side-group {
    padding-left: 4rem;
  }

  .button-group {
    display: flex;
    flex-direction: column;

    .button-detail-job {
      // width: 260px !important;
      border-radius: 10px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 0;
      background-color: #f0f0f0;
      color: #2b3c5e;
      border: none;
    }

    .button-detail-daftar {
      border: none;
      background-color: #18568b;
      // width: 200px !important;
      border-radius: 10px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 0;
      color: #ffffff;
    }

    .button-detail-daftar:hover {
      box-shadow: 3px 3px 10px 1px rgba(0, 0, 2, 0.2);
      transform: scale(1.03);
    }

    .button-detail-bookmark {
      border: 1px solid #17a2b8;
      background-color: #ffffff;
      // width: 200px !important;
      border-radius: 10px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 0;
      color: #17a2b8;
    }

    .button-detail-bookmark:hover {
      background-color: #17a2b8;
      color: #fff;
    }

    .button-detail-share {
      border: none;
      background-color: #18568b;
      // width: 200px !important;
      border-radius: 10px;
      // margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 0;
      color: #ffffff;
    }
  }

  .tags-button {
    display: inline-block;
    background-color: #5182ab;
    border-radius: 5px;
    padding: 0.25em 1em;
    margin-right: 15px;
    line-height: 1.5em;
    font-weight: 300;
    color: #ffffff;
    font-style: inherit;
    margin-bottom: 10px;
  }
}

.react-date-picker__wrapper {
  border: none !important;
}

.aplikasi-lamaran {
  padding: 1rem 6.5rem;

  .app-list {
    min-height: calc(60vh);
  }

  .job-detail-list {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
  }

  h1 {
    font-size: 18px;
    color: #2b3c5e;
    font-family: $poppins;
    font-weight: bold;
  }

  h2 {
    font-size: 16px;
    color: #2b3c5e;
    font-family: $poppins;
  }

  h3 {
    font-size: 14px;
    color: #2b3c5e;
    font-family: $poppins;
  }

  h4 {
    font-size: 12px;
    color: #2b3c5e;
    font-family: $poppins;
  }

  .job-detail-list {
    padding: 6rem 6.5rem;

    .image-job-placement {
      width: 7.5rem;
      height: 7.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      object-fit: cover;
      background-color: white;
      padding: 0.5rem;
    }
  }

  .image-job-placement {
    background-color: white;
    padding: 0.5rem;
    padding: 1rem;
    width: 5.5rem;
    height: 5.5rem;
    margin-top: 0.5rem;
    object-fit: cover;
  }
}

.box-agility {
  background-color: #fcfbfb;
  padding: 10px;
  border-radius: 10px;
  min-height: 260px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.box-agility:hover {
  transform: scale(1.03);
  transition: all 0.3s ease-in;
  // cursor: pointer;
  // border: none;
  box-shadow: 0px 2px 4px 3px #00a651;
}
.box-agility-complete {
  background-color: #eef5ed;
  padding: 10px;
  border-radius: 10px;
  min-height: 260px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
}
.hover-transform:hover {
  transform: scale(1.06);
  transition: all 0.3s ease-in;
}
.disable-box {
  cursor: not-allowed;
  background-color: #cececede !important;
}
.box-blue-etung {
  width: 94px;
  height: 94px;
  background: #ffffff;
  border: 2px solid #5aadf7;
  box-sizing: border-box;
  border-radius: 5px;
  float: left;
}
.box-black-etung {
  width: 94px;
  height: 94px;
  background: #ffffff;
  border: 2px solid #555;
  box-sizing: border-box;
  border-radius: 5px;
  float: left;
}
.btn-skillana {
  background-color: #5aadf7;
  border-color: #5aadf7;
  color: #fff;
}
.box-blue-etung-list {
  width: 75px;
  height: 75px;
  background: #ffffff;
  border: 2px solid #5aadf7;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}
.box-black-etung-list {
  width: 70px;
  height: 70px;
  background: #ffffff;
  border: 2px solid #555;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}
.box-citraleka-selected {
  box-shadow: 0px 2px 4px 3px #00a651;
  border: 2px solid #00a651 !important;
}
.box-citraleka {
  height: 150px;
  background: #ededed;
  border: 2px solid #555;
  box-sizing: border-box;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.text-submit-agility {
  color: #fff;
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}
.box-button-submit-agility {
  background-image: url("../../assets/img/btn-agility.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  height: 260px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.box-button-submit-agility:hover {
  transform: scale(1.03);
  transition: all 0.3s ease-in;
  // cursor: pointer;
  // border: none;
  box-shadow: 0px 2px 4px 3px #00a651;
}
.box-image {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  // background-color: #4f4f4f;
  margin-bottom: 10px;
}
.box-image-detail {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  // background-color: #4f4f4f;
  margin-bottom: 10px;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #b1bdc1;
  border-color: #95aab2;
}
.ribbons-list::after {
  position: absolute;
  content: attr(data-label);
  top: 9px;
  right: -14px;
  padding: 0.1rem;
  width: 7rem;
  background: $netis-primary;
  color: white;
  text-align: center;
  font-size: 10pt;
  box-shadow: 0px 5px 8px -2px rgba(0, 0, 0, 31%);
  z-index: 2;
}
.ribbons-list::before {
  position: absolute;
  top: 1.2rem;
  right: -0.5rem;
  content: "";
  background: $netis-primary;
  height: 25px;
  width: 30px;
  transform: rotate(45deg);
  z-index: 1;
}
.options-sankarta-1,
.options-sankarta-2 {
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  border: 0;
  width: 100%;
  cursor: pointer;
  font-size: 10px;
  z-index: 11;
  @include media-breakpoint-up(md) {
    font-size: 1.3em;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1.5em;
    padding: 20px;
  }
  background-color: #37a0aa;
  margin-bottom: 8px;
  outline: none !important;
}

.options-sankarta-2 {
  background-color: #f3b527;
  @include media-breakpoint-down(sm) {
    font-size: 5px;
  }
}

.options-sankarta-1-selected {
  background-color: #37a0aa;
  font-weight: bold;
  border-radius: 5pt;
  box-shadow: 0 0 0 2pt black;
  transition: all 0.1s ease-in;

  // &:focus {
  //   border-radius: 5pt;
  //   box-shadow: 0 0 0 2pt black;
  //   transition: all 0.1s ease-in;
  // }
}

.options-sankarta-2-selected {
  background-color: #f3b527;
  font-weight: bold;
  border-radius: 5pt;
  box-shadow: 0 0 0 2pt black;
  transition: all 0.1s ease-in;

  // &:focus {
  //   border-radius: 5pt;
  //   box-shadow: 0 0 0 2pt black;
  //   transition: all 0.1s ease-in;
  // }
}

.options-vacana {
  background-color: white;
  color: #36667b;
  font-weight: 800;
  padding: 8px;
  font-size: 6px;
  width: 100%;

 


  @include media-breakpoint-up(sm) {
    font-size: 8px;
  }


  @include media-breakpoint-up(md) {
    width: 75%;
    font-size: 18px;
    padding: 10px 5px;
    // margin-right: auto;
  }

  @media only screen and (min-width: 769px ) and (max-device-width: 813px) and (orientation: landscape) {
    font-size: 8px;
    width: 60%;
    padding: 10px 5px;
  }

  @include media-breakpoint-only(lg) {
    width: 50%;
  }
  border-radius: 5px;
  border: transparent;

  &:focus {
    outline: none;
  }

  // &:hover {
  //   background-color: white;
  // }
}

.options-vacana-x {
  @include media-breakpoint-up(md) {
    width: 200px;
  }
}

.options-vacana-selected {
  border-radius: 5pt;
  box-shadow: 0 0 0 2pt black;
  transition: all 0.1s ease-in;
  @include media-breakpoint-down(sm) {
    box-shadow: 0 0 0 1pt black;
  }
  // outline: none !important;
  // border: none;
  // border-radius: 5pt;
  // transition: all 0.1s ease-in;

  // &:focus {
  //   border-radius: 5pt;
  //   box-shadow: 0 0 0 2pt black;
  //   transition: all 0.1s ease-in;
  // }
}

// button.options-sankarta {
//   -webkit-animation: mover 1s infinite  alternate;
//   animation: mover 1s infinite  alternate;
// }

// @keyframes mover {
//   0% { transform: translateY(0); }
//   100% { transform: translateY(-10px); }
// }

.bg-lock {
  background-image: url("../../assets/img/GEMBOK_LOCK_TANPABINTANG.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  background-position: center;
  height: 385px;
}
.input-bedhe {
  width: 58px;
  height: 59px;
  padding: 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border: 2px solid #5aadf7;
  text-transform: capitalize;
  font-size: 1.5em;
  font-weight: bold;
  float: left;
  margin-right: 2.5px;
  margin-left: 2.5px;
}
.wrap-input {
  margin-right: auto;
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  position: relative;
  float: left;
  top: 72%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.agility-done {
  background-image: url("../../assets/img/agility/agilitySelesai.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  width: 100%;
  height: 400px;

  .btn-agility-success {
    margin-top: 140px;
    margin-right: 240px;
  }
}

.sadana-question-image {
  width: 250px;
}

.pathway-question-image {
  height: 368px;
}

.etung-wrapper {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.citraleka-wrapper {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

@media only screen and (max-width: 1200px) {
  .input-bedhe {
    width: 43px;
    height: 44px;
  }
  .wrap-input {
    top: 68%;
  }

  .btn-agility-success {
    margin-top: 160px;
    margin-right: 240px;
  }
}
@media only screen and (max-width: 991px) {
  .input-bedhe {
    width: 63px;
    height: 64px;
  }
  .wrap-input {
    top: 78%;
  }
}

@media only screen and (max-device-width: 812px) and (orientation: landscape) {
  .container {
    max-width: none;
  }
}

@media only screen and (max-width: 767px) {
  .btn-agility-success {
    margin-top: 30px !important;
    margin-right: 200px !important;
  }
}

@media only screen and (max-width: 575px) {
  .etung-answer-image-wrapper {
    text-align: center;
  }
}

@media only screen and (max-width: 520px) {
  .input-bedhe {
    width: 58px;
    height: 59px;
  }
  .wrap-input {
    top: 72%;
  }

  .btn-agility-success {
    margin-top: 0px !important;
    margin-right: 190px !important;
  }
}
@media only screen and (max-width: 460px) {
  .input-bedhe {
    width: 53px;
    height: 54px;
  }
  .wrap-input {
    top: 68%;
  }
}
@media only screen and (max-width: 430px) {
  .input-bedhe {
    width: 57px;
    height: 58px;
  }
  .wrap-input {
    top: 63%;
  }

  .btn-agility-success {
    margin-top: -4.7rem !important;
    margin-right: 150px !important;
  }

  .sadana-question-image {
    width: 100%;
  }

  .pathway-question-image {
    width: 100%;
    height: 100% !important;
  }

  .box-blue-etung-list {
    width: 65px;
    height: 65px;
  }

  .box-blue-etung-list-wrapper,
  .box-black-etung-wrapper {
    padding: 0;
  }
  .etung-wrapper,
  .citraleka-wrapper {
    border-radius: none;
    box-shadow: none;
  }

  .box-citraleka {
    height: 100px;
  }
  .citraleka-question-card-wrapper {
    padding: 0px 5px;
  }

  .citraleka-questions-img {
    width: 85px;
  }
}

#go-to:hover {
  // border: 1.5px solid #335877;
  box-shadow: 2px 2px 2px #335877;
}

.backGroundLp {
  // min-height: 90vh;
  background: url("../../assets/img/Bg-LP3.png");
  width: 100%;
  background-position: center;
  background-size: cover;
}

.backGroundLp-mobile {
  background: url("../../assets/img/Bg-mobile-ip3.png");
  width: 100%;
  background-position: center;
  background-size: cover;
  min-height: 28vh;

  @include media-breakpoint-down(sm) {
    min-height: 40vh;
  }
}

.container-fluid-home {
  padding: 1rem 6.5rem;
}

.lowongan-card {
  border: 0.5rem solid white;
  border-radius: 16px;
  margin-bottom: 0.5rem;
  padding: 1rem;
  // margin-left: 1rem;
  background-color: rgba(81, 131, 171, 0.1);
}

.panduan-assesmen {
  h1 {
    font-family: $poppins;
    text-align: center;
    margin: 1.5rem 0;
  }

  .box-panduan {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: left;
    border: 2px solid #2b3c5e;
    border-radius: 7px;
    padding: 1rem;
    // height: 30vh;
    overflow: none;

    .upper-list {
      li {
        margin-top: 0.5rem;

        .down-list {
          li {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}

.result-accordion-heading {
  background-color: #f2f2f2;
  border-radius: 10px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: #e6f2fd;
    color: #1b568d;
  }
}

.business-userGuide-point-3 {
  font-family: $poppins;
}
@keyframes blink {
  0% {
    box-shadow: 3px 3px 10px 1px green;
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: 3px 3px 10px 1px green;
  }
}

@-webkit-keyframes blink {
  0% {
    box-shadow: 3px 3px 10px 1px green;
  }
  50% {
    box-shadow: 0 0 0;
  }
  100% {
    box-shadow: 3px 3px 10px 1px green;
  }
}

.blink-shadow {
  -webkit-animation: blink 3s linear infinite;
  -moz-animation: blink 3s linear infinite;
  -ms-animation: blink 3s linear infinite;
  -o-animation: blink 3s linear infinite;
  animation: blink 3s linear infinite;
}
.success-photo-bg {
  background-color: #00a651;
  border-radius: 12px;
  color: #fff;
  border: none;
  width: 100%;
  outline: none;
}

.success-photo-bg:focus {
  outline: none;
}

.success-photo-bg:hover {
  box-shadow: 3px 3px 10px 1px rgba(111, 111, 155, 0.2);
}

.take-photo-bg {
  background-color: #f8cb00;
  border-radius: 12px;
  color: #1b568d;
  border: none;
  width: 100%;
}

.take-photo-bg:focus {
  outline: none;
}

.box-result-finger {
  border-radius: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #e6f2fd;
}

.take-photo-bg:hover {
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 2, 0.2);
}

.box-finger {
  background: #e6f2fd;
  height: 150px;
  width: 150px;
  margin-bottom: 10px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.box-finger:hover {
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 2, 0.2);
}

.img-icon-finger {
  width: 84px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.img-icon-agility {
  height: 180px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 1199px) {
  .img-icon-agility {
    height: 130px;
    top: 30%;
  }
}
.bg-open {
  background-color: #f2f8fe;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.bg-no-open {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #fff;
}
.wrap-img-finger {
  cursor: pointer;
}

.wrap-img-finger:hover {
  cursor: pointer;
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 2, 0.2);
}

.bg-fingerprint {
  background-image: url("../../assets/img/finger/frame.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 998;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}

.overlay-success-fingerprint {
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 995;
  position: absolute;
  border-radius: 12px;
  top: 0;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}

.img-icon-check {
  position: absolute;
  width: 40px;
  z-index: 996;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 0;
}

.icon-photo-finger {
  position: absolute;
  bottom: 5%;
  left: 0px;
  z-index: 999;
  margin-left: auto;
  margin-right: auto;
  right: 0px;
  cursor: pointer;
}

.icon-spinner-finger {
  position: absolute;
  bottom: 4.5%;
  margin-left: auto;
  z-index: 999;
  margin-right: auto;
  left: 0px;
  right: 0px;
  width: 80px;
  height: 80px;
}

.nav-assessmen {
  color: #305574 !important;
}

.nav-tabs .nav-assessmen.active {
  background-color: #305574 !important;
  color: #fff !important;
  border-radius: 90px;
}
.flex-container {
  flex-direction: column;
  flex-wrap: nowrap;
  display: flex;
}
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

.btn-outline-blue {
  border: 2px solid #5aadf7;
  background-color: #fff;
  color: #5aadf7;
  font-weight: bold;
  padding: 10px 25px;
}
.btn-outline-blue:hover {
  color: #fff;
}
.btn-actived-blue {
  background-color: #63c2de;
  color: #fff;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 2, 0.2);
}
.btn-completed {
  background-color: seagreen;
  color: #fff;
  border: 2px solid #00a651;
}
.btn-outline-blue:disabled,
.btn-outline-blue[disabled] {
  border: 2px solid #c4c4c4;
  background-color: #fff;
  color: #c4c4c4;
  font-weight: bold;
  padding: 10px 25px;
}
.btn-actived-blue:disabled,
.btn-actived-blue[disabled] {
  border: 2px solid #5aadf7;
  background-color: #63c2de;
  opacity: 1;
  cursor: pointer;
  color: #fff;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 2, 0.2);
}
.option-spm {
  border: 1px solid #c4c4c4;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 20px;
  width: 180px;
  height: 100px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  display: flex; // make us of Flexbox
  align-items: center; // does vertically center the desired content
  justify-content: center; // horizontally centers single line items
  text-align: center;
  position: relative;
}
.modal-center {
  -webkit-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  top: 25%;
  margin: 0 auto;
}
.option-spm:hover {
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 2, 0.2);
}
.option-spm-selected {
  border: 3px solid #00a651;
  box-shadow: 0px 0px 8px 2px rgba(0, 166, 81, 0.25);
}
.img-option {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}
.background-img-result-spm {
  background: url("../../assets/img/spm-result.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  display: inline-block;
  min-height: 700px;
  position: relative;
}
.middle-box {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 30%;
  padding: 40px;
}
.box-blue {
  background-color: #5aadf7;
  box-shadow: 0px 4px 10px #5aadf7;
  border-radius: 15px;
  padding: 10px 60px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  cursor: auto;
  font-size: 2em;
  font-weight: bold;
  display: inline-block;
}
.box-blue-young {
  background-color: #f0fbff;
  border-radius: 25px;
  padding: 10px 25px;
  margin-left: auto;
  margin-right: auto;
  color: #5aadf7;
  cursor: auto;
  font-size: 1.4em;
  display: inline-block;
}
.question-show {
  width: 250px;
  display: inline-block;
}
.question-none {
  display: none;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}
.grafik-agility {
  max-width: 320px;
  max-height: 320px;
  padding: 50px 0;
  background-image: url("../../assets/img/agility/grafik.png");
  background-position: calc(50% + 14px) calc(50% - 1px);
  background-repeat: no-repeat;
  background-size: auto 95%;
  display: flex;
  justify-content: center;
  align-items: center;

  // @include media-breakpoint-up(sm) {
  //   max-width: 480px;
  //   max-height: 480px;
  //   padding: 80px 0;
  // }

  // @include media-breakpoint-down(xs) {
  //   .header-landing-page {
  //     background-image: url("../../assets/img/agility/grafik.png");
  //     // background-size: cover;
  //     width: 100%;
  //     height: 100%;
  //     background-repeat: no-repeat;
  //     background-position-y: 100%;
  //   }
  // }

  // @include media-breakpoint-up(md) {
  //   max-width: 600px;
  //   max-height: 600px;
  // }

  // @include media-breakpoint-up(lg) {
  //   max-width: 800px;
  //   max-height: 800px;
  // }

  // @include media-breakpoint-up(xl) {
  //   max-width: 1000px;
  //   max-height: 1000px;
  // }
}
@media only screen and (max-width: 1199px) {
  .grafik-agility {
    background-position: calc(50% + 13px) calc(50% - 2px);
  }
}
@media only screen and (max-width: 992px) {
  .grafik-agility {
    background-position: calc(50% + 13px) calc(50% - 1px);
  }
}
@media only screen and (max-width: 770px) {
  .grafik-agility {
    background-position: calc(50% + 12px) calc(50% - 2px);
  }
}
@media only screen and (max-width: 760px) {
  .grafik-agility {
    background-position: calc(50% + 12px) calc(50% - 1px);
  }
}

@media only screen and (max-width: 596px) {
  .grafik-agility {
    background-position: calc(50% + 13px) calc(50% - 1px);
  }
}

.grafik-papikostick-user {
  max-width: 320px;
  max-height: 320px;
  padding: 50px 0;
  background-image: url("../../assets/img/papikostick/square-user.png");
  background-position: calc(50% - 3px) calc(50% + 4px);
  background-repeat: no-repeat;
  background-size: auto 95%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 300px) {
    .cardHome {
      height: 55%;
    }
  }

  @include media-breakpoint-up(sm) {
    max-width: 480px;
    max-height: 480px;
    padding: 80px 0;
  }

  @include media-breakpoint-down(xs) {
    .header-landing-page {
      background-image: url("../../assets/img/imacandphone.png");
      // background-size: cover;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position-y: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 600px;
    max-height: 600px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 800px;
    max-height: 800px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1000px;
    max-height: 1000px;
  }
}

.grafik-papikostick-user-en {
  max-width: 320px;
  max-height: 320px;
  padding: 50px 0;
  background-image: url("../../assets/img/papi_en.png");
  background-position: calc(50% - 3px) calc(50% + 4px);
  background-repeat: no-repeat;
  background-size: auto 95%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 300px) {
    .cardHome {
      height: 55%;
    }
  }

  @include media-breakpoint-up(sm) {
    max-width: 480px;
    max-height: 480px;
    padding: 80px 0;
  }

  @include media-breakpoint-down(xs) {
    .header-landing-page {
      background-image: url("../../assets/img/imacandphone.png");
      // background-size: cover;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position-y: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 600px;
    max-height: 600px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 800px;
    max-height: 800px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1000px;
    max-height: 1000px;
  }
}

@include media-breakpoint-down(sm) {
  .assetIQ {
    width: 50%;
    margin: auto;
    display: block;
  }
  .title-landingpage {
    margin-top: 5%;
  }

  .text-headerlp {
    text-align: justify;
  }

  .paddingCard {
    padding-left: -16px;
    padding-right: -16px;
  }

  // .asesmenCard {
  //   margin-left: -6px;
  //   margin-right: -6px;
  // }

  .title-landingpage3 {
    margin-top: -10%;
  }

  .menubimetrik {
    margin-top: 20px;
  }

  .background-img-result-spm {
    background: url("../../assets/img/spm-result.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    display: inline-block;
    min-height: 700px;
    position: relative;
    margin-top: -10%;
  }

  .middle-box {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 30%;
    padding: 40px;
    font-size: 130%;
  }
  .box-blue {
    background-color: #5aadf7;
    box-shadow: 0px 4px 10px #5aadf7;
    border-radius: 15px;
    padding: 7px 40px;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    cursor: auto;
    font-size: 1.5em;
    font-weight: bold;
    display: inline-block;
  }
  .box-blue-young {
    background-color: #f0fbff;
    border-radius: 25px;
    // padding: px 25px;
    margin-left: auto;
    margin-right: auto;
    color: #5aadf7;
    cursor: auto;
    font-size: 1em;
    display: inline-block;
  }
}

@include media-breakpoint-down(md) {
  // .canvas-Lpmobile {
  //   margin-top: -40%;
  // }

  .Bg-mobile-ip2 {
    width: 100%;
  }

  .text-landingpage2 {
    font-size: 25px;
    line-height: 31px;
  }

  .text-sublandingpage2 {
    font-size: 13px;
  }

  .menubimetrik {
    margin-top: 20px;
  }
}

// @media (min-width: 576px) {
//   .title-landingpage {
//     margin-top: 20%;
//   }
// }

.iconNavbar {
  width: 17px;
  height: 17px;
}

.validate-account {
  border: 1px solid #f5222d;
  background-color: #ffeeee;
  color: #f5222d;
  padding-left: 12px;

  p {
    font-weight: 600;
  }

  .importantLogo {
    width: 38px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}

.about-skillana {
  background-color: #1b568d;
}

.img-ps:hover {
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 2, 0.2);
  transform: scale(1.03);
}

.webcam-videos {
  height: "150px";
  width: "400px";
  position: "absolute";
  right: "0%";
}

.bg-header-carausel {
  background-image: url("../../assets/img/notfound.png");
  // width: 500px;
  // height: 500px;
  position: relative;

  width: 650px;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.icon-delete-answer {
  &:hover {
    cursor: pointer;
    color: red;
    transition: color 0.2s ease-in;
  }
}

.answerDropDown:disabled {
  background-color: #b1bdc1;
}

.answerDropDownToggle {
  background-color: transparent;

  &:hover,
  &:active {
    background-color: transparent;
  }

  &:disabled {
    background-color: #b1bdc1;
  }
}

.content-promotion-1-wrapper {
  background: url("../../assets/img/promotion-bg-1.png") no-repeat;
  background-position: right top;
  margin-top: 75px;

  @include media-breakpoint-down(md) {
    background: url("../../assets/img/promotion-mobile-bg-1.png") no-repeat;
    background-position: right top;
  }
}

.content-promotion-2-wrapper {
  background: url("../../assets/img/promotion-bg-2.png") no-repeat;
  background-position: left top;
  padding-top: 260px;
  margin-top: -50px;

  @include media-breakpoint-down(md) {
    background: url("../../assets/img/promotion-mobile-bg-2.png") no-repeat;
    background-position: left top;
    padding-top: 100px;
    margin-top: 0 !important;
  }
}

.content-promotion-3-wrapper {
  background: url("../../assets/img/promotion-bg-3.png") no-repeat;
  background-position: right bottom;

  @include media-breakpoint-down(md) {
    background: url("../../assets/img/promotion-mobile-bg-3.png") no-repeat;
    background-position: right bottom;
    padding-bottom: 100px;
  }
}

.promotion-text-heading {
  font-weight: 600;
}

// .text-desc-left,
// .text-desc-right {
//   @include media-breakpoint-down(sm) {
//     text-align: left;
//   }
// }

/* Extra small devices (phones, 600px and down) */
@media (min-width: 768px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.8rem 1.5rem;
    color: white;
    font-size: 0.8em;
    font-weight: bold;
  }

  .wrap-content {
    padding: 180px 30px 50px;
  }
}

@media (min-width: 768px) {
  .onScroll .navbar-nav .nav-link {
    padding: 0.8rem 1.5rem;
    color: #2b3c5e;
    font-size: 0.8em;
    font-weight: bold;
  }

  .wrap-content {
    padding: 180px 30px 50px;
  }
}

@media only screen and (max-width: 1144px) {
  .home-card {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .imageIphone2 {
    margin-top: "-240px";
  }
}
.opt-busines {
  margin-left: 1.8rem !important;
  margin-right: 1.8rem !important;
}
@media only screen and (max-width: 1200px) {
  .col-md-3 .img-option {
    width: 70px;
  }
  .col-md-3 .option-spm {
    width: 100%;
    border-radius: 10px;
  }
}
@media only screen and (max-width: 993px) {
  .img-option {
    width: 90px;
  }
  .option-spm {
    width: 100%;
    border-radius: 10px;
  }
}
@media only screen and (max-width: 930px) {
  .home-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 700px) {
  .home-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .opt-busines {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .img-option {
    width: 80px;
  }
  .option-spm {
    width: 100%;
    border-radius: 10px;
  }
  .col-sm-5ths {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bg-fingerprint {
    background-image: url("../../assets/img/finger/frame_mobile.png");
  }
  .hand-background-kanan {
    background-image: url("../../assets/img/kanan.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 998;
  }

  .hand-background-kiri {
    background-image: url("../../assets/img/kiri.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 998;
  }

  .webcam-videos {
    height: "110px !important";
    width: "200px !important";
    position: "absolute";
    right: "0%";
  }

  .icon-video {
    position: absolute;
    bottom: 50%;
    left: 50%;
    cursor: pointer;
  }

  .icon-question {
    position: absolute;
    bottom: 50%;
    left: 30%;
    cursor: pointer;
  }

  .icon-next {
    position: absolute;
    bottom: 50%;
    left: 70%;
    cursor: pointer;
  }

  .icon-replay {
    position: absolute;
    bottom: 50%;
    left: 10%;
    cursor: pointer;
  }

  .icon-video-single {
    position: absolute;
    bottom: 50%;
    left: 41%;
    cursor: pointer;
  }

  .icon-spinner {
    position: absolute;
    bottom: 46.4%;
    left: 40%;
    width: 80px;
    height: 80px;
  }

  .list-job-placement {
    text-align: center;
  }

  .list-job-landingPage {
    text-align: center;
  }

  .job-placement-lamaran {
    text-align: center;
    background-color: "red";
  }

  .wa-mobile {
    display: block;
  }

  .wa-web {
    display: none;
  }

  .header-logo-mobile {
    display: inline-block;
    margin-top: -70px;
  }

  .header-logo-web {
    display: none;
  }

  .footer-list {
    padding-left: 0px;
    padding-top: 35px;
    line-height: 3em;
  }

  .tagline {
    font-size: 0.3em;
  }

  .content-web {
    display: none;
  }

  .content-mobile {
    display: inline-block;
  }

  .navbar-brand-full {
    display: none;
  }

  .box-control {
    height: 160px;
  }

  .box-control-palmistry {
    height: 160px;
  }
}

@media only screen and (max-width: 600px) {
  .aplikasi-lamaran {
    padding: 1rem;
  }

  .grafik-papikostick-user {
    max-width: 650px;
    max-height: 650px;
    // background-image: url("../../assets/img/papikostick/user.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 700px;
  }

  .job-detail-list {
    ul {
      padding: 0 1.5rem;
    }

    .job-placement {
      padding: 1rem 1.5rem;
    }
  }

  .container-fluid-home {
    padding: 1rem 1rem;
  }

  .hero-content {
    max-width: 90%;
    position: absolute;
    top: 300px;
  }

  .hero-content h1 {
    font-family: $poppins;
    font-size: 1.75rem;
  }

  .btn-hero {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: -8px;
  }

  .about {
    background-size: 8px;
    background-position: 5% 0%;
  }

  .about br {
    display: none;
  }

  .about h1 {
    font-family: $poppins;
    font-size: 1.75rem;
  }

  .hr-main {
    width: 70px;
    border-top: 3px solid #ffb95b;
  }

  .unduh-app:first-child {
    margin-right: 20px;
  }

  .footer-content {
    padding: 0 15px;
  }

  .logo_white {
    width: 200px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

.app-header .navbar-brand {
  width: 155px;
  margin-top: -30px;
}

.item-test {
  box-shadow: 0 2px 3px rgba(33, 33, 33, 0.1);
}

.item-testred {
  box-shadow: 0 2px 3px rgba(33, 33, 33, 0.1);
}

// end landing page

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
    height: 600px;
  }
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
    height: 600px;
  }
}
@media (max-width: 495px) {
  .opt-busines {
    margin-left: 0.7rem !important;
    margin-right: 0.7rem !important;
  }
}
@media (max-width: 420px) {
  .opt-busines {
    margin-left: 0.4rem !important;
    margin-right: 0.4rem !important;
  }
}
@media (min-width: 992px) {
  .main {
    margin-left: 250px !important;
  }
}

@media (max-width: 992px) {
  .card-group {
    .card:not(:last-child) {
      border-top-right-radius: 20px !important;
      border-bottom-right-radius: 20px !important;
    }
  }

  .app-header .navbar-brand {
    width: 155px;
    margin-top: 0px;
  }
}

@media (max-width: 1024px) {
  .job-detail-page {
    .job-detail-list {
      padding: 6rem 2.5rem;

      .image-job-placement {
        width: 6rem;
        height: 6rem;
      }
    }

    .breadcrumb-header {
      .breadcrumb {
        background-color: #e2e2e2;
        border: none;
        padding-left: 2.5rem;
      }
    }

    .button-side-group {
      padding-left: 2rem;
    }
  }
}

@media (max-width: 768px) {
  .app-header .navbar-brand .navbar-brand-minimized {
    display: inline-block;
    margin-left: -165%;
  }
}

@media (max-width: 600px) {
  .menu-img img {
    width: 70%;
  }

  .rbc-calendar {
    overflow: scroll;

    .rbc-toolbar,
    .rbc-month-view,
    .rbc-time-view {
      min-width: 650px;
    }
  }

  .sidebar .nav {
    width: 200px;
  }

  .plr-4 {
    padding: 0;
  }

  .card-group .card-login-form {
    form {
      width: 100%;
    }
  }

  // .modal-dialog {
  //     // top: 25%;
  // }
  .button-add-doc,
  .button-add-doc:hover {
    width: 90%;
  }

  .loading {
    left: 25%;
  }

  .filter-date-attendance {
    margin: 0 !important;
  }

  .dashboard-card .ul-dashboard {
    padding-inline-start: 0;
  }
}

@media (max-width: 450px) {
  .img-mbti {
    height: 150px;
  }

  .job-detail-page {
    .job-detail-list {
      padding: 6rem 1rem;

      .image-job-placement {
        width: 100%;
        height: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        object-fit: cover;
      }
    }

    .button-side-group {
      padding-left: 1rem;
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      .button-detail-daftar {
        width: 260px;
      }
    }
  }

  .breadcrumb {
    padding-left: 1rem !important;

    a,
    li {
      font-size: 14px;
    }
  }
}

@media (min-width: 790px) {
  .attendance {
    display: none;
  }
}

.bg-stripe {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23f5f5f5' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23f5f5f5' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-color: #f2f2f2;
}

.icon-bookmark {
  position: absolute;
  top: 6%;
  right: 4%;
}

.button-detail {
  color: white;
  border-radius: 10px;
  border: none;
  font-size: 14px;
}

// LIVE CHAT

.liveChat {
  font-weight: 700;
  font-size: 36px;
  color: #5aadf7;
}

.textLivechat {
  text-align: center;
  font-size: 20px;
  color: #5aadf7;
  letter-spacing: 3px;
  margin-bottom: 50px;
}

.textLivechat2 {
  margin-right: 65px;
  margin-left: 65px;
  margin-top: 65px;
}

.content-tabs {
  flex-grow: 1;
}
.content-chat {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content-chat h2 {
  padding: 0px 0 5px 0px;
}

.button-netis-outline {
  border: 2px solid #335877;
  background-color: #fff;
  color: #335877;
  border-radius: 10px;
}

.button-netis-outline:hover {
  background-color: #335877;
  color: #fff;
}

.button-netis-outline:focus {
  background-color: #335877;
  color: #fff;
}

.button-netis-outline:disabled {
  border: 2px solid #335877;
  background-color: #fff;
  color: #335877;
  cursor: no-drop;
}

.button-netis-outline:active {
  /* background-color:  #335877; */
  color: #fff;
  transform: translateY(1px);
  background-color: #335877;
}


.button-outline-red{
  border: 1px solid red;
  background-color: #fff;
  color: red;
}

.button-outline-red:hover,
.button-outline-red:active {
  border: 1px solid red;
  background-color: red;
  color: #fff;
}

.div-cancel-outline {
  .btn-secondary:not(:disabled):not(.disabled):active {
    border: 1px solid red;
    background-color: #fff;
    color: red;
  }
}

.button-netis-color {
  border: 2px solid #fff;
  background-color: #335877;
  color: #fff;
  border-radius: 10px;
}

.button-netis-color:hover {
  background-color: #1b486d;
  color: #fff;
}

.button-netis-color:focus {
  background-color: #fff;
  color: #335877;
}

.button-netis-color:disabled {
  border: 2px solid #fff;
  background-color: #335877;
  color: #fff;
  cursor: no-drop;
}

.button-netis-color:active {
  /* background-color:  #fff; */
  color: #335877;
  transform: translateY(1px);
  background-color: #fff;
}

// .content hr {
//   width: 100px;
//   height: 2px;
//   background: #222;
//   margin-bottom: 5px;
// }
.content-chat p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}

.tabs {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: gray;
  color: white;
  text-align: center;
  font-weight: bold;
}

.numberCircle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #5aadf7;
  color: white;
  text-align: center;
  font-weight: bold;
}

.textActive {
  color: gray;
}

.ChatActive {
  color: black;
}

.storeZoom {
  // transform-origin: 10% 15%;
  transition: transform 0.5s, filter 1.5s ease-in-out;
  filter: grayscale(0);
}

.storeZoom:hover {
  filter: grayscale(0);
  transform: scale(1.1);
}

.sectionSatu {
  background-color: #ebf6fd;
  border-radius: 15px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.vid-agility {
  width: 100%;
  height: 100%;
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: black;

  @media screen and (max-width:813px) and (orientation: portrait) {
    background-color: white;
  }
  
  @media screen and (max-width: 813px) and (orientation: landscape ) {
    background-color: black;
  }

 
}

.sankarta-container {
  position: relative;
  // width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .fixedSection-2 {
    position: fixed;
    left: 6vw;
    width: 70%;
    @include media-breakpoint-up(md) {
      width: 50%;
      left: 14vw;
      padding-top: 10px;
    }
  }
  .fixed-button {
    position: fixed;
    left: 35vw;
    bottom: 15vh;
  }
}

.button-sankarta {
  position: absolute;
  z-index: 99;
  width: 100%;
  top: 50%;
}

.pulse-button-1 {
  font-size: 10px;
  width: 75%;
}

.pulse-button-2 {
  font-size: 7px;
  width: 100%;
}

.vid-submit-button {
  padding: 3px;
  font-size: 12px;
  border-radius: 5px;
  @include media-breakpoint-up(md) {
    padding: 5px;
    font-size: 15px;
  }
}

.fixedSection-Vacana {
  position: fixed;
  width: 50vw;
  right: 2vw;
  top: 48vh;
  @include media-breakpoint-up(md) {
    top: 45vh;
  }
}


.fixedSection-Vacana-2 {
  position: fixed;
  width: 36vw;
  @media only screen and (max-width: 2560px) {
    top: calc(41vh - 25%);
    right: 8.5vw;
    width: 33vw;
  }
  @media only screen and (max-width: 1440px) {
    top: calc(50vh - 26%);
    right: 4vw;
  }
  @media only screen and (max-width: 1024px) {
    top: calc(50vh - 25%);
    right: 4.6vw;
  }

  @media only screen and (max-width: 813px) {
    top : calc(38vh - 25%);
    right: 10vw;
  }
  @media only screen and (max-width: 768px) {
    top: calc(42vh - 25%);
    right: 5vw;

  }
  @media only screen and (max-width: 425px) {
    top: calc(50vh - 15%);
    right: 5.8vw;
  }
  @media only screen and (max-width: 375px) {
    top: calc(50vh - 13%);
    right: 5.7vw;
  }
  @media only screen and (max-width: 320px) {
    top: calc(50vh - 13%);
    right: 6.5vw;
  }

  .col-6 {
    @include media-breakpoint-down(sm) {
      padding-right: 2px;
      padding-left: 2px;
    }
  }

  .options-vacana,
  .options-vacana-selected {
    width: 100%;
    background-color: #fbab34;
    color: #fff;
    font-size: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    height: 40px;

    @media only screen and (max-width: 813px) {
      font-size: 10px;
    }
    @include media-breakpoint-up(md) {
      padding-top: 6px;
      padding-bottom: 6px;
      width: 75%;
      font-size: 12px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 13px;
      margin-bottom: 30px;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 15px;
      height: 60px;
      margin-bottom: 40px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 5px;
    }
  }
}
.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 1.3rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 470px) {
  .margin-top--small .inputStyle {
    width: 2rem !important;
    height: 2rem;
    margin: 0 5px;
    font-size: 1rem;
  }

  .inputStyle {
    width: 2rem !important;
    height: 2rem;
  }

  .card > form > p {
    font-size: 1.2em !important;
  }
}

.containerInput {
  justify-content: center;
  align-items: center;
}
.text-link {
  color: #5aadf7;
}

.text-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
