.answers-group1,
.answers-group2 {
  border: 3px solid transparent;
  border-radius: 12px;
  background-color: #fcfbfb;

  &:hover {
    border: 3px solid #00a651;
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
  }
}

.answers-group2 {
  background-color: #fffb94;
}
